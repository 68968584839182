import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import Asset from '../AssetRowComponent';
import { ScrollableList } from '../ScrollableListComponent';
import { pageSize } from '../constants';
import { formatConditionIndexOverviewRequest } from '../helpers';


const AssetOverviewComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState({});
    const {
        pageNumber,
        assetOverviewLoadingStatus,
        assetOverviewList,
        hasMore,
    } = props.model;
    const { getAssetConditionIndicesRequest } = props.actions;
    const {
        assetNumber,
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        withVisibleFilter,
        asset
    } = props;

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter
            ));
        }, searchBoxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        searchBoxFilter,
        JSON.stringify(selectedFilters),
        JSON.stringify(conditionFilter),
        assetTypeFilter
    ]);


    const handleSelectedItem = (asset) => {
        setSelectedAsset(asset?.asset || asset);
    };

    useEffect(() => {
        handleSelectedItem(asset);
    }, [asset]);

    const handleReset = () => {
        setSelectedAsset(null);
    };

    const renderAssets = () => {
        return map(assetOverviewList, (item) => {
            return <Asset key={item.assetId} selectedAsset={selectedAsset} asset={item} onSelectedItem={handleSelectedItem} />;
        });
    };

    return <ScrollableList
        noDataLabel={translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoItems')}
        className={` asset overview ${withVisibleFilter ? 'with-filter' : ''}`}
        assetNumber={assetNumber}
        loadingStatus={assetOverviewLoadingStatus}
        hasMore={hasMore}
        pageSize={pageSize}
        loadMore={() => getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
            pageNumber,
            selectedFilters,
            searchBoxFilter,
            conditionFilter,
            assetTypeFilter
        ))}
        renderList={renderAssets}
        selectedAsset={selectedAsset}
        hasData={assetOverviewList}
        asset={selectedAsset}
        onReset={handleReset}
    />;
};

AssetOverviewComponent.propTypes = {
    model: PropTypes.shape({
        pageNumber: PropTypes.number,
        assetOverviewLoadingStatus: PropTypes.string,
        assetOverviewList: PropTypes.array,
        hasMore: PropTypes.bool
    }),
    actions: PropTypes.shape({
        getAssetConditionIndicesRequest: PropTypes.func
    }),
    assetNumber: PropTypes.number,
    selectedFilters: PropTypes.object,
    searchBoxFilter: PropTypes.string,
    conditionFilter: PropTypes.array,
    assetTypeFilter: PropTypes.string,
    withVisibleFilter: PropTypes.bool
};


export default AssetOverviewComponent;
