import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { getLoggerTableColumnConfig } from './helpers';

import { GeneralTable, LoadingStatusWrapper } from 'sharedComponents';

import './style.scss';

const LoggerDownloadComponent = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const loggersData = model.loggers?[model.loggers]:[];
    return <Container fluid className='logger-download-container'>
        <LoadingStatusWrapper loadingStatus={[model.loggerFileLoadingStatus]}>
            <GeneralTable
                scroll={{ x: 720, y: null }}
                tableKey='id'
                columns={getLoggerTableColumnConfig(translate, actions.downloadLogger, model.loggers, model.dateKind)}
                data={loggersData || []}
                emptyText={translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                paginationProps={{ pageSize: 12 }}
            />
        </LoadingStatusWrapper>
    </Container>;
};

LoggerDownloadComponent.propTypes = {
    selectedAsset: PropTypes.object.isRequired,
    model: PropTypes.shape({
        loggers: PropTypes.array,
        dateKind: PropTypes.number,
        loggersLoadingStatus: PropTypes.string,
        loggerFileLoadingStatus: PropTypes.string,
    }),
    actions: PropTypes.shape({
        downloadLogger: PropTypes.func.isRequired
    }),

};

export default LoggerDownloadComponent;
