import { filter, sortBy, map } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsKPILoaded, selectShouldScrollToSelection } from '../selectors';
import { getTranslatedKPIName, getTranslatedKPINameV2 } from 'helpers/translateHelper';


const translateAndSortKPIs = (kpis) => {
    return sortBy(map(kpis, (kpi) => {
        return {
            ...kpi,
            displayName: getTranslatedKPIName(kpi)
        };
    }), (kpi) => kpi?.displayName?.toLowerCase());
};

const translateAndSortTrendKPIs = (kpis) => {
    return sortBy(map(kpis, (kpi) => {
        return {
            ...kpi,
            displayName: getTranslatedKPINameV2(kpi)
        };
    }), (kpi) => kpi?.displayName?.toLowerCase());
};

export const getKPIListForAsset = (kpiList) => {
    const selectedKPIType = useSelector(({ chartView }) => chartView.selectedKPIType);
    if (selectedKPIType === 1) {
        const parameterKPIList = translateAndSortTrendKPIs(filter(kpiList?.kpis, (kpi) => {
            return kpi && kpi.appendCodeToTranslation === true;
        }));

        const standardKPIList = translateAndSortTrendKPIs(filter(kpiList?.kpis, (kpi) => {
            return kpi && kpi.appendCodeToTranslation === false;
        }));
        return {
            kpis:
            {
                parameterKPIList,
                standardKPIList
            },
            loadingStatus: kpiList?.loadingStatus
        };


    } else {
        const parameterKPIList = translateAndSortKPIs(filter(kpiList?.kpis, (kpi) => {
            return kpi && kpi.allSignal === true;
        }));

        const standardKPIList = translateAndSortKPIs(filter(kpiList?.kpis, (kpi) => {
            return kpi && kpi.allSignal === false;
        }));
        return {
            kpis:
            {
                parameterKPIList,
                standardKPIList
            },
            loadingStatus: kpiList?.loadingStatus
        };
    }
};
export const getSelectedParameterSignals = (chartData) => {
    return filter(chartData, (data) => data.kpi.appendCodeToTranslation).map(filteredData => filteredData.kpi);
};


export const useKPILoadingTrigger = () => {

    const [scrollTriggered, setScrollTriggered] = useState(false);
    const isKpiLoaded = useSelector((state) => selectIsKPILoaded(state));
    const shouldScrollToSelection = useSelector((state) => selectShouldScrollToSelection(state));

    useEffect(() => {
        if (!scrollTriggered && isKpiLoaded && shouldScrollToSelection) {
            setScrollTriggered(true);
        }
    }, [isKpiLoaded]);

    return scrollTriggered;
};


export const isKpiSelected = (chartData, kpi) => {
    if (chartData?.filter((selectedKPI) => selectedKPI?.kpi?.componentID === kpi?.componentID && selectedKPI?.kpi.measurementTypeKey === kpi?.measurementTypeKey)?.length > 0) {
        return true;
    }
    return false;
};
export const isKpiSelectedTrend = (chartData, kpi) => {
    if (chartData?.filter((selectedKPI) => selectedKPI?.kpi?.timeseriesKey === kpi?.timeseriesKey)?.length > 0) {
        return true;
    }
    return false;
};
