import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { IconPowertrainGroup } from 'svgIcons/MotionPortalIcons';
import { Expandable } from 'sharedComponents/Expandable';
import Asset from '../AssetRowComponent';
import { ScrollableList } from '../ScrollableListComponent';
import { pageSize } from '../constants';
import {
    formatConditionIndexOverviewRequest,
    renderConditionIndicesComponent,
    renderLimitsComponent,
    renderThresholdSummary,
    renderTrendSummary
} from '../helpers';


const PowertrainOverviewComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const [selectedPowertrain, setSelectedPowertrain] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState({});
    const {
        pageNumber,
        powertrainOverviewList,
        powertrainOverviewLoadingStatus,
        hasMore
    } = props.model;
    const { getPowertrainConditionIndicesRequest } = props.actions;
    const {
        assetNumber,
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        withVisibleFilter,
        powertrain
    } = props;

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getPowertrainConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter
            ));
        }, searchBoxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        searchBoxFilter,
        JSON.stringify(selectedFilters),
        JSON.stringify(conditionFilter),
        assetTypeFilter
    ]);


    const handleSelectedItem = (asset) => {
        setSelectedAsset(asset);
    };

    useEffect(() => {
        setSelectedPowertrain(powertrain?.powertrainId);
        handleSelectedItem(powertrain?.selectedAsset);
    }, [powertrain]);

    const handleReset = () => {
        setSelectedAsset(null);
    };

    const handlePowertrainClick = (powertrain) => {
        setSelectedPowertrain(powertrain?.powertrainId);
    };

    const renderExpandableTitle = (item) => {

        return <>
            <div className='item-name'>
                <div className='item-icon'><IconPowertrainGroup /></div>
                <div className='name'>{item.powertrainName} ({item?.assets?.length})</div>
            </div>
            {renderConditionIndicesComponent(item)}
            {renderLimitsComponent(item)}
            {renderThresholdSummary(item)}
            {renderTrendSummary(item)}
        </>;
    };

    const renderPowertrains = () => {
        return map(powertrainOverviewList, (item) => {
            return <Expandable
                id={item.powertrainId}
                key={item.powertrainId}
                title={renderExpandableTitle(item)} selectedAssetGroup
                onClick={() => handlePowertrainClick(item)}
                open={item.powertrainId === selectedPowertrain}
            >
                {!item.assets || item.assets.length === 0 ?
                    <div className='no-available-assets'>
                        {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoAvailableAssets')}
                    </div> :
                    <div key={item.powertrainId} className='asset-list-of-asset-group'>
                        {map(item.assets, (asset) => {
                            return <Asset key={asset.assetId} asset={asset} limitSummary={item?.limitsSummary} selectedAsset={selectedAsset} onSelectedItem={handleSelectedItem}/>;
                        })}
                    </div>}
            </Expandable>;
        });
    };

    return <ScrollableList
        noDataLabel={translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoItems')}
        className={` asset overview ${withVisibleFilter ? 'with-filter' : ''}`}
        assetNumber={assetNumber}
        loadingStatus={powertrainOverviewLoadingStatus}
        hasMore={hasMore}
        pageSize={pageSize}
        loadMore={() => getPowertrainConditionIndicesRequest(formatConditionIndexOverviewRequest(
            pageNumber,
            selectedFilters,
            searchBoxFilter,
            conditionFilter,
            assetTypeFilter
        ))}
        renderList={renderPowertrains}
        selectedAsset={selectedAsset}
        hasData={powertrainOverviewList}
        selectedPowertrain={selectedPowertrain}
        onReset={handleReset}
    />;
};

PowertrainOverviewComponent.propTypes = {
    model: PropTypes.shape({
        pageNumber: PropTypes.number,
        powertrainOverviewLoadingStatus: PropTypes.string,
        powertrainOverviewList: PropTypes.array,
        hasMore: PropTypes.bool
    }),
    actions: PropTypes.shape({
        getPowertrainConditionIndicesRequest: PropTypes.func
    }),
    assetNumber: PropTypes.number,
    selectedFilters: PropTypes.object,
    searchBoxFilter: PropTypes.string,
    conditionFilter: PropTypes.array,
    assetTypeFilter: PropTypes.string,
    withVisibleFilter: PropTypes.bool
};


export default PowertrainOverviewComponent;
