import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { find, map } from 'lodash';
import { toLocaleDateTimeString } from 'helpers/dateHelper';
import { dateKind, EventTypes } from '../../constants';
import { getToggleOptions } from './helpers';
import { ToggleButton } from 'sharedComponents';
import { IconLeftArrow } from 'svgIcons/MotionPortalIcons';
import './style.scss';
import useNewCapability from 'sharedHooks/useNewCapability';


const EventDetailsCMDComponent = (props) => {
    const { t: translate } = useTranslation();
    const event = props.model.selectedEvent.rawEvent;
    const selectedAsset = props.model.selectedAsset;
    let activeTab = props.model.activeTab;
    const capability = useNewCapability();
    const toggleOptions = getToggleOptions(translate, capability);
    const { getLoggerId } = props.actions;

    const selectedDateKind = props.model.dateKind;

    const selectedTabComponent = find(toggleOptions, { value: activeTab });
    if (!selectedTabComponent) {
        activeTab = toggleOptions?.length && toggleOptions[0]?.value;
    }
    const handleTabSelection = (tab) => {
        props.actions.setActiveTabAction(tab);
    };

    useEffect(() => () => {
        props.setSelectedEvent(null);
    }, []);

    useEffect(() =>  {
        if (event) {
            getLoggerId(event);
        }
    }, [event]);

    return (
        <div className='event-detailsCMD-content'>
            <div className='event-details-header'>
                <div className='event-type-icon'>{EventTypes.find(et => et.typeID === event.severityCode)?.icon}</div>
                <div className='event-type-group'>
                    <div className='event-name'>{event.messageText}</div>
                    <div>{selectedAsset.componentName}</div>
                    <div className='event-logged-on'>
                        {translate('ABB.Powertrain.Frontend.eventDetailsOccurredOnLbl')}: {toLocaleDateTimeString(event.timestamp, { asUTC: selectedDateKind === dateKind.UTC, showOffset: true })}
                    </div>
                </div>
                <div className='back-to-event-list'>
                    <div className='switch-component'>
                        <IconLeftArrow />
                        <span> {translate('ABB.Powertrain.Frontend.eventDetailsBackToLbl')} </span>
                        <button onClick={() => props.setSelectedEvent(null)}>
                            <span className='event-list-lbl'> {translate('ABB.Powertrain.Frontend.eventDetailsEventListLbl')} </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className='event-details-tab-selector'>
                <ToggleButton
                    options={toggleOptions}
                    onSelected={handleTabSelection}
                    selectedValue={props.model.activeTab}
                />
            </div>
            <Container fluid id='event-details-container'>
                <Row>
                    <Col md={12} className='event-details-tab-container'>
                        {
                            map(toggleOptions, (option) => {
                                return option.component && option.value === activeTab && <div key={option.value}>
                                    <option.component
                                        selectedAsset={props.model.selectedAsset}
                                        selectedEvent={props.model.selectedEvent}
                                        toggleOptions={toggleOptions}
                                        handleTabSelection={handleTabSelection}
                                        activeTab={activeTab}
                                    />
                                </div>;
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

EventDetailsCMDComponent.propTypes = {
    actions: PropTypes.shape({
        setActiveTabAction: PropTypes.func,
        getLoggerId: PropTypes.func
    }),
    model: PropTypes.shape({
        activeTab: PropTypes.any,
        selectedEvent: PropTypes.object,
        dateKind: PropTypes.number,
        selectedAsset: PropTypes.object
    }),
    setSelectedEvent: PropTypes.func
};

export default EventDetailsCMDComponent;

