import React from 'react';
import PropTypes from 'prop-types';
import { getAssetIcon } from 'helpers/icons';
import { renderThreshold, renderTrend, renderAssetLimitsSummary } from './helpers';
import { newConditionIndices } from 'helpers/constants';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { useTranslation } from 'react-i18next';
import { assetActive } from './constants';


const AssetRowComponent = ({ asset, selectedAsset, onSelectedItem }) => {
    const { t: translate } = useTranslation();

    return <div className={`item ${selectedAsset?.assetId === asset?.assetId ? assetActive.ACTIVE: ''}`} onClick={() => onSelectedItem(asset)}>
        <div className='item-status'>
        <IconCondition condition={newConditionIndices[asset?.condition]?.iconID} />
        </div>
        <div className='item-name'>
            <div className='item-icon'>
                {getAssetIcon(asset.assetType, { width: 20, height: 20 })}
            </div>
            <div className='name'>{asset.assetName}</div>
        </div>
        <div className='ci-summary'>
            {asset?.conditionIndex?.condition === newConditionIndices.Unsupported.conditionIndexID ? translate('ABB.Powertrain.Frontend.conditionNotSupported') : asset?.conditionIndex?.condition}
        </div>
        <div className='limit-Summary'>
            {renderAssetLimitsSummary(asset)}
        </div>
        {renderThreshold(asset)}
        {renderTrend(asset)}
    </div>;
};

AssetRowComponent.propTypes = {
    asset: PropTypes.shape({
        assetId: PropTypes.number,
        assetType: PropTypes.string,
        assetName: PropTypes.string
    }),
    selectedAsset: PropTypes.object,
    onSelectedItem: PropTypes.func,
    limitSummary: PropTypes.object
};

export default AssetRowComponent;
